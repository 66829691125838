import MenuDots from '../Components/MenuDots'
import DeleteComponent from '../Components/DeleteComponent'
export default {
  name: "ProjectList",
  props: {
    projects: Array,
  },
  data() {
    return {
      delUrl: '/projects/del-projs',
      trigActive: false,
      trigTxt: '',
      iDeleteDialogVisible: false,
      iRenameDialogVisible: false,
      typeDelete: '',
      typeDeleteError: '',
      boutDel: [],
      boutRenTitle: null,
      boutRenId: null,
      typeRenameError: '',
      loading:false,
      renLoading:false,
    };
  },
  components: {
    MenuDots,
    DeleteComponent
  },
  methods: {
    //delete notification
    delNotification(msg, title, type) {
      this.$notify({
        type: type,
        title: title,
        message: msg,
        position: 'bottom-left',
      });
    },
    //make the menu dots look active when the dropdown for the item is open
    makeActive(trig) {
      this.trigActive = !this.trigActive;
      this.trigActive ? this.trigTxt = trig : this.trigTxt ='';
    },
    loadDeleteDialog(res) {
      if(res == 'false') {
        this.iDeleteDialogVisible = false
      } else if(res == 'true'){
        this.iDeleteDialogVisible = true;
      }
    },
    reloadProjects() {
      this.$emit('rebootProjectsFromChild');
    },
    async renameProject(id) {
      try {
          // make the error empty
          this.typeRenameError = '';
          // if there is no error, send request to the backend, else throw the error
          if(this.boutRenTitle.length > 0 && this.boutRenId) {
          this.renLoading = true
          const response = await this.axios.post('/project/'+id, {title: this.boutRenTitle});
          let msg = response.data.message;
          if(response.status === 200) {
              // empty the input field
              this.boutRenTitle= null;
              // hide the modal
              this.iRenameDialogVisible = false;
              this.boutRenId= null;
              
              this.renLoading = false;
              // show success notification
              this.delNotification(msg, 'Renamed', 'success');
              this.$emit('rebootProjectsFromChild');
          } else {
              // msg = 'Something went wrong, kindly try again later'
              this.renLoading = false;
              // show error notification
              this.delNotification(msg, 'Error', 'error');
          }
          } else {
          this.typeRenameError = 'Field cannot be empty.';
          }
      }
      catch(err) {
          console.log(err);
      }
    },

  },
}
