export default {
    name: "NewProject",
    props: {
        project: [Object, Boolean],
        width: String
    },
    data() {
        let sets = {
            title: '',
            description: '',
            drm: false,
            lock: false,
            password: '',
        };
        let id = false;
        if (this.project) {
            Object.assign(sets, this.project);
            if (sets.id) {
                id = sets.id;
            }
            delete sets.id;
        }
        return {
            id: id,
            sets: sets,
            loading: false,
            dialogVisible: true,
            collapsed: true,
            passRequired: false
        };
    },
    methods: {
        save() {
            if (!this.sets.title) {
                return false;
            }
            if (this.id) {
                this.update();
            } else {
                this.create();
            }
        },
        create() {

            if (this.sets.password == '' && this.sets.lock === true) {
                this.passRequired = true
                return false;
            }

            this.loading = true;
            this.axios({
                method: 'post',
                url: '/projects',
                data: {
                    project_title: this.sets.title,
                    description: this.sets.description,
                    // lock: this.sets.lock,
                    // drm: this.sets.drm,
                    password: this.sets.password,
                }
            }).then(response => {
                this.loading = false;
                this.$router.push('/projects');
                this.dialogVisible = false;
                this.$emit('listProject');
            }).catch(response => {
                this.loading = false;
                alert('something wrong');
            });
        },
        update() {

        }
    }
}
