<template>
    <div v-if="permission">
        <div v-if='!noDesktop' style='position: relative'>
            <el-popover
                placement="bottom-start"
                :width="170"
                trigger="hover"
                content="Click here to create your first project"
            >
                <template #reference>
                <el-button
                    class="toolbar__btn toolbar__create"
                    @click="checkOwnerStatus"
                    :loading='loading'>
                    +  Add New Folder
                </el-button>
                </template>
            </el-popover>
            
            <!-- <base-tooltip
                type='project'
                position='absolute'
                buttonText='Close'
                end='project_page'
                arrowPositon='top'
                top='50px'
            /> -->
        </div>
        
        <div v-else>
            <el-dropdown @command="handleDropdownCommand($event)"
                    trigger="click"
                    class="action__dropdown"
                    size="medium"
                    placement="left">
                <span class="btn btn-default btn-primary-outline el-dropdown-link flex-center">
                    <MenuDots></MenuDots>
                </span>
            <el-dropdown-menu slot="dropdown" class="dropdown__menu">
                <el-dropdown-item command="createProject">Create Project</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        </div>
    </div>
</template>

<script>

import MenuDots from './MenuDots';

export default {
    props: ['noDesktop', 'projectsCount'],
    components: {
        MenuDots,
    },
    data: () => ({
        loading: false,
        permission: true
    }),
    created(){
        // var permissions = this.$store.state.userData.user_permissions
        // var $this = this;

        // this.permission = false

        // if(typeof permissions !== 'undefined' ){

        //     permissions.forEach(function(ele, i){
        //         // console.log(ele.name, 'project  -- ');
        //         if(ele.name == "Create Project"){

        //             $this.permission = true

        //         }

        //     })
        // }
    },
    methods: {
        handleDropdownCommand(command)
        {
            switch (command) {
                case 'createProject':
                    this.checkOwnerStatus();
                    // this.$emit('showCreateModal', true);
                break;
            }
        },
        checkOwnerStatus()
        {
            this.$emit('showCreateModal', true)

            this.loading = true;
            var projectsCount = this.$props.projectsCount;
            this.axios.get('restrictions/index')
                .then(response => {
                    this.loading = false;
                    if (response.data) {
                        const usage = response.data.usage;
                        if(usage.projects.total == 'unlimited') {
                            return this.$emit('showCreateModal', true)
                        }

                        if (usage.projects.used >= usage.projects.total) {
                            return this.$emit('limitReached');
                        } 
                        
                        this.$emit('showCreateModal', true)
                    }
                })
                .catch (error => {
                    this.loading = false;
                    console.log(error);
                })
        }
    }
}
</script>

<style scoped lang='scss'>
.toolbar__btn {
    // color: $common-ui-white;
    // font-size: $font-common-size;
    // line-height: 19px;
    // font-weight: 400;
    // border-radius: 8px;
    // padding: 9px 20px;
    // height: 50px;
    // margin-bottom: 20px;
        padding: 10px 25px 9px;
    background-color: var(--buttonThemeColor);
    border: none;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    color: white;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
     @include responsive(mobile_campaign){
            height: 44px;
            font-size: $font-medium-size;
            padding: 9px 15px !important;
        }
    &.toolbar__upload {
        margin-right: 32px;
        background-color: $dark-ui-dark;
        opacity: .7;

        &:hover {
            opacity: .9;
        }
    }

    // &.toolbar__create {
    //     background-color: $common-ui-blue;

    //     &:hover {
    //         background-color: $color-blue-hover;
    //     }
    // }

    &.toolbar__create_with_0 {
        background-color: $common-ui-blue;
        text-align: right;
        &:hover {
            background-color: $color-blue-hover;
        }
    }
}

.el-dropdown-link {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid $common-ui-blue !important;
    background-color: transparent;
    padding: 0 !important;
    margin-left: 20px;
    position: relative;
}

.el-dropdown-link:hover {
    background-color: $common-ui-white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.dropdown__menu {
    list-style: none;
    width: max-content;
    position: relative;
    border: 1px solid $common-ui-blue;
    border-radius: 5px;
    padding: 0 15px;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

.dropdown__menu .el-dropdown-menu__item:hover {
    color: $common-ui-blue;
}

.dropdown__menu::before {
    content: '';
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    box-sizing: border-box;
    top: 11px;
    right: 0;
    left: 134px;
    margin: auto;
    transform: rotate(135deg);
    border: 1px solid $common-ui-blue;
    border-bottom-color: transparent;
    border-right-color: transparent;
    background-color: $common-ui-white;
    border-radius: 3px;
}
</style>