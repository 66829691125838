<template>
    <div class='tutorial-video'>
        <div>
            <loader v-if='video && !trigger'></loader>
        </div>
        <div v-if='video' :class='!trigger ? "d-none" : ""'>
            <div class='close-tutorial' @click='close' :class='!trigger ? "d-none" : ""'><i class="fas fa-times"></i></div>
<!--            <Video-->
<!--                :video='video'-->
<!--                ref='video_tutorial'-->
<!--                :presenter='true'-->
<!--                @canplay='$emit("playing", true)'>-->
<!--            </Video>-->
        </div>
    </div>
</template>

<style lang='less'>
    .tutorial-video {
        position: fixed;
        width: 30%;
        height: auto;
        z-index: 5;
        bottom: 20px;
        left: 20px;

        .close-tutorial {
            position: relative;
            margin: auto;
            width: max-content;
            background: var(--color-red) !important;
            padding: 0px 9px;
            color: white;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
            cursor: pointer;
        }
    }

    .d-none {
        display: none;
    }
</style>

<script>
// import Video from '../Video';
import Loader from '../Common/Loader';
export default {
    name: 'PopoverVideo',
    components: {  Loader },
    props: ['video', 'trigger'],
    methods: {
        close()
        {
            this.$emit('close', true);
        }
    }
}
</script>