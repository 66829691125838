<template>
<!-- Delete Modal -->
    <!-- <div> -->
        <el-dialog
        title="ARE YOU SURE?"
        :visible.sync="updtDeleteComDialogVisible"
        @closed="clearDelDialoogInput"
        :width="windowWidth > 1024 ? `40%` : '95%'"
        top="13%"
        :center="true"
        class="no-el-border"
        style="padding: 15px 0; ocerflow: hidden">
        <div style="margin-bottom:20px; text-align:center; padding: 10px">
            <span>You are about to delete this folder and all it's contents. This action is irreversible</span>
        </div>
        <div class="i-el-dialog__body"> 
        <!-- <div class="i-el-dialog__body" style=" width: 100%; position: absolute;">  -->
            <div class="del-input-g-cover">
                <span style="color:#EE595199;">Type <span class="cap-del-text">DELETE</span> to confirm</span>
                <div style="margin-top: 10px">
                    <input type="text" class="del-input" v-model="typeDelete" />
                    <div v-if="typeDeleteError" class="deleteError">
                        <img src="../../../../assets/svg/4kplayer_icons_svg/Group 10998.svg" alt="" style="margin-top: -3px; ">   
                        <span> {{ typeDeleteError }}</span>
                    </div>
                </div>
            </div>
            <div class="i-dialog-btns">
                <span v-if="iDeleteLoading">
                    <span v-loading="iDeleteLoading" style="position:relative"></span>
                </span>
                <span v-else>
                    <button @click.prevent="updateDialogVsb('false')" class="i-dialog-btn i-dialog-btn-del-cancel">Cancel</button>
                    <button @click.prevent="deleteProject(idToDel)" class="i-dialog-btn i-dialog-btn-del-confirm">Delete</button>
                </span>
            </div>
        </div>
        <!-- <span slot="footer" class="dialog-footer i-foot" style="background-color:#FDE8E7; text-alighn:center">
            <el-button @click="updtDeleteComDialogVisible = false">Cancel</el-button>
            <el-button type="primary" @click="updtDeleteComDialogVisible = false">Confirm</el-button>
        </span> -->
        </el-dialog>
    <!-- </div> -->
</template>
<script>
export default {
    name: 'DeleteComponent',
    props: ['iDeleteComDialogVisible', 'idToDel', 'inReq', 'delUrl'],
    data() {
        return {
            typeDelete: '',
            typeDeleteError: '',
            boutDel: null,
            iDeleteLoading:false,
            updtDeleteComDialogVisible: false,
            windowWidth: null,
        };
    },
    mounted: function () {
        window.addEventListener('resize', this.onResize);
        this.onResize();
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth <= 1024) return false;
            return true;
        },
        clearDelDialoogInput() {
            this.typeDeleteError = '';
            this.typeDelete = '';
            this.updateDialogVsb('false');
            this.$emit('clearProjectIds');
        },
        confirmDelete() {
            return this.typeDelete === 'DELETE' ? true : false;
        },
        async deleteProject(ids) {
            try {
                let reqData = {
                    req_type : this.inReq
                }
                if (this.inReq == 'Video' || this.inReq == 'Videos') {
                    reqData.videos = ids;
                }else {
                    reqData.projects = ids;
                }
                
                // make the error empty
                this.typeDeleteError = '';
                // if there is no error, send request to the backend, else throw the error
                if(this.confirmDelete()) {
                this.iDeleteLoading = true
                const response = await this.axios.post(this.delUrl, reqData);
                let msg;
                if(response.status === 200) {
                    // empty the input field
                    this.typeDelete = '';
                    // hide the modal
                    this.updateDialogVsb('false')
                    this.$emit('clearProjectIds');
                    msg = response.data.message;
                    this.iDeleteLoading = false;
                    // show success notification
                    this.delNotification(msg, 'Deleted', 'success');
                    this.$emit('bootProjects');
                } else {
                    msg = 'Something went wrong, kindly try again later'
                    this.iDeleteLoading = false;
                    // show error notification
                    this.delNotification(msg, 'Error', 'error');
                }
                } else {
                this.typeDeleteError = 'You must type DELETE to confirm.';
                }
            }
            catch(err) {
                console.log(err);
            }
        },
            //delete notification
        delNotification(msg, title, type) {
            this.$notify({
                type: type,
                title: title,
                message: msg,
                position: 'bottom-left',
            });
        },
        updateDialogVsb(item) {
            this.$emit('reloadDelDialog', item)
        }
    },
    watch: {
        iDeleteComDialogVisible(value) {
            this.updtDeleteComDialogVisible = value;
        },
    },
}
</script>

<style>
    /* .no-el-border {
        border: none !important;
    } */
    .i-el-dialog__body {
        background-color: #FDE8E7;
        /* padding: 10px 20px 30px; */
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border: 1px solid #EE5951;
        position: absolute;
        width: 100%;
        left: 0;
    }
    .i-foot {
        text-align: center !important;
        background-color: red !important;
    }
    .i-dialog-btns {
        margin-top:25px; 
        text-align:center;
        padding: 0 20px 20px 20px;
    }
    .i-dialog-btn {
        border-radius: 18px;
        padding: 5px 15px;
        position: relative;
        transition: all .5s;
    }
    .i-dialog-btn:hover {
        top: -2px;
        transform: scale(1.03);
    }
    .i-dialog-btn-del-cancel {
        background-color: #21455E;
        opacity: .7;
        color: #fff;
        border: none;
    }
    .i-dialog-btn-del-confirm {
        background: #EE5951 0% 0% no-repeat padding-box;
        border: 1px solid #EE5951;
        opacity: 1;
        color: #fff;
        margin-left: 20px;
    }
    
    .i-dialog-btn-ren-cancel {
        background-color: #21455E;
        opacity: .7;
        color: #fff;
        border: none;
    }
    .i-dialog-btn-ren-confirm {
        background: #0DABD8 0% 0% no-repeat padding-box;
        opacity: 1;
        color: #fff;
        margin-left: 20px;
        border: none;
    }
    .del-input {
        width: 100%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #EE5951;
        border-radius: 4px;
        opacity: 0.23;
        color: #000;
        padding: 2px 10px
    }
    .del-input:focus {
        outline: 1px solid #f02b21;
        opacity: 1;
        border: none !important;
    }
    .ren-input {
        width: 100%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #00ACDC;
        border-radius: 4px;
        opacity: .3;
        color: #000;
    }
    .ren-input:focus {
        border: 2px solid #00ACDC;
        outline: none;
        opacity: 1;
    }
    .cap-del-text {
        color:#EE5951; 
        font-weight: bold;
    }
    .del-input-g-cover {
        width:100%; 
        margin-top: 15px;
        padding: 10px;
    }
    .deleteError {
        text-align: left;
        font: normal normal normal 13px/15px Helvetica Neue;
        letter-spacing: 0px;
        color: #EE5951;
        opacity: 1; margin-top:10px;
    }
</style>