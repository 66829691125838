<template>
  <div
    class="toolbar__search new"
  >
    <div class="toolbar__search ml-auto" style="position: relative">
      <div class="flex-center search__icon">
        <el-input v-if='!onKey' v-model="query" :placeholder="placeholder || 'Search Your Files'" @input='$emit("query", query)'>
                    <template slot="prepend">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.6" height="16.6"
                                viewBox="0 0 16.6 16.6">
                            <path id="icon_search-2"
                                    d="M12.7,11.23a6.777,6.777,0,0,0,1.4-4.174A7.02,7.02,0,0,0,7.1,0,7.105,7.105,0,0,0,0,7.056a7.105,7.105,0,0,0,7.1,7.056,6.667,6.667,0,0,0,4.2-1.391l3,2.981a.971.971,0,0,0,1.4,0,.957.957,0,0,0,0-1.391Zm-5.6.8A5.022,5.022,0,0,1,2,7.056a5.1,5.1,0,1,1,10.2,0,5.022,5.022,0,0,1-5.1,4.969Z"
                                    transform="translate(0.3 0.3)" fill="#00acdc" stroke="#fff"
                                    stroke-width="0.6"/>
                        </svg>
                    </template>
                </el-input>
        <el-input v-else v-model="query" :placeholder="placeholder || 'Search Your Files'">
          <template slot="prepend">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16.6"
              height="16.6"
              viewBox="0 0 16.6 16.6"
            >
              <path
                id="icon_search-2"
                d="M12.7,11.23a6.777,6.777,0,0,0,1.4-4.174A7.02,7.02,0,0,0,7.1,0,7.105,7.105,0,0,0,0,7.056a7.105,7.105,0,0,0,7.1,7.056,6.667,6.667,0,0,0,4.2-1.391l3,2.981a.971.971,0,0,0,1.4,0,.957.957,0,0,0,0-1.391Zm-5.6.8A5.022,5.022,0,0,1,2,7.056a5.1,5.1,0,1,1,10.2,0,5.022,5.022,0,0,1-5.1,4.969Z"
                transform="translate(0.3 0.3)"
                fill="#00acdc"
                stroke="#fff"
                stroke-width="0.6"
              />
            </svg>
          </template>
        </el-input>
      </div>
      <div class="query-tip">
        <Popover :message="tip" top="-60px" left="40px" v-if="showTip" />
      </div>
    </div>
  </div>
</template>

<script>
import Popover from "../../../Common/Popover";

export default {
  props: ["noDesktop", "onKey", "tip", "placeholder"],
  data: () => ({
    query: "",
    showTip: false,
  }),
  components: {
    Popover,
  },
  mounted() {
    const key = this.$props.onKey;
    const $this = this;
    document.addEventListener("keydown", function (e) {
      if (e.key === key || e.code === key) {
        $this.$emit("query", $this.query);
      }
    });
  },
  watch: {
    showTip() {
      setTimeout(() => {
        this.showTip = false;
      }, 2000);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-input-group--prepend > div {
  height: 50px;
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
  background: #f8fafb;
  border-color: #e2e5ed;
  padding:6px 15px 0 25px;
  svg path{
    fill: #000000b3;
  }
}
::v-deep .el-input-group > input {
  height: 50px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-left: 0;
  background: #f8fafb;
  border-color: #e2e5ed;
  &::placeholder{
    color : #21455e;
  }
}
</style>
