<template>
    <div class="projects-and-count" >
        <div class="d-flex align-items-center">
            <div class="page__name">
                Projects
            </div>
            <div class="project__count">{{ count ? count : '0' }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['count', 'noDesktop']
}
</script>

<style lang='scss' scoped>
.projects-and-count {
    margin-bottom: 18px;
padding-top: 15px;
    .page__name {
        margin-bottom: 0;
        font-size: 28px;
        line-height: 28px;
        color: var(--textThemeColor) !important;
        margin-right: 10px;
        font-weight: 400;
        
    }

    .project__count {
        font-size: 24px;
        line-height: 28px;
        color: var(--textThemeColor) !important;
        opacity: .7;
    }
}

@media (max-width: 960px) {
    .desktop-only {
        display: none;
    }
    .page__name,
    .project__count {
        font-size: 20px !important;
    }
}
</style>