import {find} from 'lodash';
import AppHelper from '../../../../utils/AppHelper';

export default {
    name: 'ProjectGrid',
    props: {
        projects: [Array, Object],
        searchQuery: String,
        user: Object
    },
    created() {

    },
    computed: {
        scopeProjects() {

            return this.projects.map(p => {
                p.isDragging = p.isDragging || 0;
                return p;
            });
        },
        store() {
            return this.$store.state;
        }
    },
    data() {
        return {
            isVideoDragging: 0,
            draggingId: false,
            dialogVisible: false,
            password: '',
            passRequired: false,
            passmsg: '',
            selProject: null
        };
    },
    methods: {

        verifyPass() {


            if (this.password === '') {
                this.passRequired = true
                this.passmsg = 'Password is required'
                return false;
            } else {
                this.passRequired = false
                this.passmsg = ''
            }


            this.axios({
                method: 'post',
                url: '/projects/verify/pass',
                data: {
                    password: this.password,
                    project_id: this.selProject.id,
                }
            }).then(response => {


                if (response.data.success) {
                    this.passRequired = false
                    this.passmsg = ''
                    this.createCookie("adilo_project_pass_"+this.selProject.id, true, 1440)
                    this.viewProject(this.selProject, false);

                }
                if (!response.data.success) {
                    this.passRequired = true
                    this.passmsg = response.data.message
                }


            }).catch(response => {


            });
        },

        getCookie(name) {
            var dc = document.cookie;
            var prefix = name + "=";
            var begin = dc.indexOf("; " + prefix);
            if (begin == -1) {
                begin = dc.indexOf(prefix);
                if (begin != 0) return null;
            } else {
                begin += 2;
                var end = document.cookie.indexOf(";", begin);
                if (end == -1) {
                    end = dc.length;
                }
            }
            // because unescape has been deprecated, replaced with decodeURI
            //return unescape(dc.substring(begin + prefix.length, end));
            return decodeURI(dc.substring(begin + prefix.length, end));
        },

        createCookie(name, value, minutes) {
            if (minutes) {
                var date = new Date();
                date.setTime(date.getTime() + (minutes * 60 * 1000));
                var expires = "; expires=" + date.toGMTString();
            } else {
                var expires = "";
            }
            document.cookie = name + "=" + value + expires + "; path=/";
        },
        video_title(project, item) {
            this.$router.push({
                name: "VideoEditor",
                params: {projectId: project.id, id: item},
            });
        },
        checkAccess(project) {
            const {access} = project;
            const userId = this.$store.state.userData.id;
return true;
            // var p_access = project.project_access;
            // return project.owner === userId
            //     || project.team_owner_id === userId || p_access.includes(userId);
        },
        itemDragEnter(item) {
            item.isDragging++;
            if (item.isDragging) {
                this.draggingId = item.id;
                this.$emit('dragging', true);
            }
        },
        itemDragLeave(item) {
            item.isDragging--;
            if (!item.isDragging && this.draggingId === item.id) {
                this.draggingId = false;
                this.$emit('dragging', false);
            }
        },
        onChangeFile(e, item) {
            const files = e.target.files;
            if (files.length) {
                this.uploadVideo(files[0], item);
            }
            e.target.value = '';
        },
        async uploadVideo(file, item) {
            const row = await this.$store.dispatch('VideoUpload/upload', {
                file: file,
                user: this.user,
                projectId: item.id
            });
            this.$router.push({name: 'VideoUploadProgressing', params: {id: row.hashID, hash: row.hashID}});
        },
        viewProject(item, check = true) {

            if (item.locked === 1 && check) {

                var myCookie = this.getCookie("adilo_project_pass_"+item.id);

                if (myCookie === null) {

                    this.dialogVisible = true
                    this.selProject = item
                    return false;
                }
            }

            if (this.checkAccess(item)) {
                this.$router.push({name: 'Project All Videos', params: {id: item.id}});
            }
        }
    }
}
